/*eslint-disable */
import { lazy } from 'react';
import { paths as bingoPaths } from './Bingo/routes';
import { paths as walletPaths } from './Wallet/routes';

export const signInRoute = '/entrar';
export const signUp = '/cadastro';
export const recoverPassword = '/alterar-senha';
export const home = '/';
export const loteriaPopular = '/loteria-popular';
export const slots = '/slots';
export const cassino = '/cassino';
export const cassinoGames =
  '/cassinoGames/:game_name/:vendor/:game_id/:provider_id';
export const maintenance = '/aguarde';
export const vibraGames = '/vibragames';
export const caletaGames = '/caletagames';
export const everyMatrix = '/every-matrix';
export const sports = '/esportes';
export const liveSports = '/esportes-ao-vivo';
export const betHistory = '/esportes-minhas-apostas';
export const affiliates = '/afiliados';

export const ondeEstou = '/onde-estou';

const routes = [
  {
    key: 'signIn',
    path: signInRoute,
    authenticated: false,
    redirectWhenIsLogged: home,
    component: lazy(() => import('./SignIn')),
  },
  {
    key: 'vibraGames',
    path: vibraGames,
    authenticated: true,
    component: lazy(() => import('./GamesVibra')),
  },
  {
    key: 'caletaGames',
    path: caletaGames,
    authenticated: true,
    component: lazy(() => import('./GamesCaleta')),
  },
  {
    key: 'everyMatrix',
    path: everyMatrix,
    authenticated: true,
    component: lazy(() => import('./GamesEveryMatrix')),
  },
  {
    key: 'signUpCode',
    path: `${signUp}/code`,
    authenticated: false,
    // redirectWhenIsLogged: home,
    component: lazy(() => import('./SignUp/ConfirmSignUp')),
  },
  {
    key: 'signUp',
    path: [`${signUp}/:code`, signUp],
    authenticated: false,
    redirectWhenIsLogged: home,
    component: lazy(() => import('./SignUp')),
  },
  {
    key: 'recoverPassword',
    path: recoverPassword,
    authenticated: false,
    component: lazy(() => import('./RecoverPassword')),
  },
  // {
  //   key: 'sports',
  //   path: sports,
  //   authenticated: true,
  //   component: lazy(() => import('./Sports'))
  // },


  // Hide popular lottery by now
  // {
  //   key: 'loteriaPopular',
  //   path: loteriaPopular,
  //   component: lazy(() => import('./LoteriaPopular/index')),
  // },
  // {
  //   key: 'loteriaPopular_PassoAPasso',
  //   path: '/passo-a-passo',
  //   component: lazy(() => import('./LoteriaPopular/Passo')),
  // },
  // {
  //   key: 'loteriaPopular_Aposta',
  //   path: '/aposta',
  //   authenticated: true,
  //   component: lazy(() => import('./LoteriaPopular/Aposta')),
  // },
  // {
  //   key: 'loteriaPopularResult',
  //   path: '/loteria-popular-resultado',
  //   authenticated: true,
  //   component: lazy(() => import('./LoteriaPopular/Result')),
  // },


  {
    key: 'slots',
    path: slots,
    authenticated: false,
    component: lazy(() => import('./Slots')),
  },
  {
    key: 'cassino',
    path: cassino,
    authenticated: false,
    component: lazy(() => import('./Cassino')),
  },
  {
    key: 'cassinoGames',
    path: cassinoGames,
    authenticated: true,
    component: lazy(() => import('./CassinoGames')),
  },
  {
    key: 'cassinoCategory',
    path: cassino + '/:category',
    authenticated: false,
    component: lazy(() => import('./Cassino/category')),
  },
  {
    key: 'videoBingo',
    path: '/video-bingo',
    authenticated: false,
    component: lazy(() => import('./Cassino')),
  },
  {
    key: 'liveBingo',
    path: '/bingo-ao-vivo',
    component: lazy(() => import('./LiveBingo')),
  },
  {
    key: 'liveBingo2',
    path: '/bingo-ao-vivo2',
    component: lazy(() => import('./MenuBody')),
  },
  {
    key: 'wallet',
    path: walletPaths,
    authenticated: true,
    component: lazy(() => import('./Wallet')),
  },
  {
    key: 'affiliates',
    path: affiliates,
    component: lazy(() => import('./Affiliates')),
  },
  {
    key: 'maintenance',
    path: maintenance,
    component: lazy(() => import('./Maintenance')),
  },
  {
    key: 'home',
    path: home,
    authenticated: false,
    component: lazy(() => import('./Home')),
  },
  {
    key: 'bingo',
    path: bingoPaths,
    authenticated: true,
    component: lazy(() => import('./Bingo')),
  },
  {
    key: 'terms_of_service',
    path: '/termos-de-servico',
    authenticated: false,
    component: lazy(() => import('./Terms/TermsOfService')),
  },
  {
    key: 'security_and_privacity',
    path: '/seguranca-e-privacidade',
    authenticated: false,
    component: lazy(() => import('./Terms/ServiceAndPrivacity')),
  },
  {
    key: 'responsable_game',
    path: '/jogo-responsavel',
    authenticated: false,
    component: lazy(() => import('./Terms/ResponsableGame')),
  },
  {
    key: 'disputeResolution',
    path: '/disputa-de-resolucao',
    authenticated: false,
    component: lazy(() => import('./Terms/DisputeResolution')),
  },
  {
    key: 'lesgilation',
    path: '/legislacao',
    authenticated: false,
    component: lazy(() => import('./Terms/Lesgislation')),
  },
  {
    key: 'accountsPayoutsBonuses',
    path: '/accounts-payouts-and-bonuses',
    authenticated: false,
    component: lazy(() => import('./Terms/AccountsPayoutsBonuses')),
  },
  {
    key: 'antiMoneyLaudering',
    path: '/anti-money-laudering',
    authenticated: false,
    component: lazy(() => import('./Terms/AntiMoneyLaudering')),
  },
  {
    key: 'policiesPrivacy',
    path: '/policies-privacy',
    authenticated: false,
    component: lazy(() => import('./Terms/PoliciesPrivacy')),
  },
  {
    key: 'selfExclusion',
    path: '/self-exclusion',
    authenticated: false,
    component: lazy(() => import('./Terms/SelfExclusion')),
  },
  {
    key: 'howItWorks',
    path: '/como-funciona',
    authenticated: false,
    component: lazy(() => import('./Terms/HowItWorks')),
  },
  {
    key: 'doubts',
    path: '/duvidas',
    authenticated: false,
    component: lazy(() => import('./Terms/Doubts')),
  },
  {
    key: 'rules',
    path: '/regras',
    authenticated: false,
    component: lazy(() => import('./Terms/Rules')),
  },
  {
    key: 'aboutUs',
    path: '/quem-somos',
    authenticated: false,
    component: lazy(() => import('./AboutUs')),
  },


  // Hide sports by now
  // {
  //   key: 'sports',
  //   path: sports,
  //   authenticated: false,
  //   component: lazy(() => import('./Esportes')),
  // },
  // {
  //   key: 'liveSports',
  //   path: liveSports,
  //   authenticated: false,
  //   component: lazy(() => import('./EsportesAoVivo')),
  // },
  // {
  //   key: 'sportsBetHistory',
  //   path: betHistory,
  //   authenticated: false,
  //   component: lazy(() => import('./EsportesHistoricoApostas')),
  // },


  {
    key: 'userProfile',
    path: '/perfil-usuario',
    authenticated: true,
    component: lazy(() => import('./UserProfile')),
  },
  {
    key: 'loteriaPopularAoVivo',
    path: '/loteria-popular-ao-vivo',
    authenticated: false,
    component: lazy(() => import('./LoteriaPopularAoVivo')),
  },
  {
    key: 'ondeEstou',
    path: '/onde-estou',
    authenticated: false,
    component: lazy(() => import('./Terms/locale.js')),
  },
  {
    key: 'promotion',
    path: '/promocao',
    authenticated: false,
    component: lazy(() => import('./Promotion')),
  },
  {
    key: 'promotionDeposit',
    path: '/promocao/promocaoDeposito',
    authenticated: false,
    component: lazy(() => import('./Promotion/PromotionDeposit')),
  },
  {
    key: 'promotionSignup',
    path: '/promocao/promocaoCadastro',
    authenticated: false,
    component: lazy(() => import('./Promotion/promotionSignup')),
  },
  {
    key: 'notFound',
    component: lazy(() => import('./NotFound')),
  },
];

export default routes;
