/* eslint-disable */

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './errorBoundary';
import '@fontsource/roboto';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './i18n';
import './output.css';

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker
//     .getRegistrations()
//     .then(registrations => {
//       return Promise.all(
//         registrations.map(registration => registration.unregister())
//       );
//     })
//     .then(() => {
//       console.log('PNR: Service worker unregister.');
//     })
//     .catch(err => {
//       console.log('PNR ERROR: Falha ao sair do service worker', err);
//     });
// }

// if ('caches' in window) {
//   caches
//     .keys()
//     .then(cacheNames => {
//       return Promise.all(cacheNames.map(name => caches.delete(name)));
//     })
//     .then(() => {
//       console.log('PNR: Cache limpo.');
//     })
//     .catch(err => {
//       console.log('PNR ERROR: Falha ao limpar o cache do service worker', err);
//     });
// }

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
