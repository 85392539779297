/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import PropTypes from 'prop-types';
import { Button } from 'components/Tailwind/button/button';

export const Countdown = ({ timeInSeconds = 300 }) => {
  const [timeLeft, setTimeLeft] = useState(timeInSeconds);
  const [isOpen, setIsOpen] = useState(false);
  const [alreadyExpired, setAlreadyExpired] = useState(false);

  const handleTimeEnd = () => {
    setIsOpen(false);
    setTimeLeft(120);
    setAlreadyExpired(true);
  };

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft(prev => prev - 1);
      }, 1000);

      return () => clearInterval(timerId);
    }

    if (timeLeft === 0 && !alreadyExpired) {
      setIsOpen(true);
    }
  }, [timeLeft]);

  const formatTime = seconds => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    return { hours, minutes, secs };
  };

  const { hours, minutes, secs } = formatTime(timeLeft);

  return (
    <>
      {timeLeft > 0 && (
        <>
          <div className='tw-flex tw-flex-col md:tw-flex-row tw-gap-3 tw-p-4 tw-bg-primary/10 tw-rounded-md tw-mt-4 tw-text-primary'>
            <p>
              Se este é o seu primeiro depósito, você ganhará 100% do valor
              depositado em bônus até R$100,00
            </p>
            <div className='tw-flex tw-gap-1 tw-items-center tw-justify-end md:tw-justify-start'>
              <p className='tw-bg-primary/20 tw-rounded-md tw-flex tw-items-center tw-justify-center tw-w-7 tw-h-7'>
                <span className='tw-leading-3 tw-text-sm'>
                  {hours.toString().padStart(2, '0')}
                </span>
              </p>
              <span>:</span>
              <p className='tw-bg-primary/20 tw-rounded-md tw-flex tw-items-center tw-justify-center tw-w-7 tw-h-7'>
                <span className='tw-leading-3 tw-text-sm'>
                  {minutes.toString().padStart(2, '0')}
                </span>
              </p>
              <span>:</span>
              <p className='tw-bg-primary/20 tw-rounded-md tw-flex tw-items-center tw-justify-center tw-w-7 tw-h-7'>
                <span className='tw-leading-3 tw-text-sm'>
                  {secs.toString().padStart(2, '0')}
                </span>
              </p>
            </div>
          </div>
        </>
      )}
      <Dialog open={isOpen} onClose={() => {}} className='tw-relative tw-z-50'>
        <div
          className='tw-fixed tw-inset-0 tw-bg-gray-700/70'
          aria-hidden='true'
        />
        <div className='tw-fixed tw-inset-0 tw-flex tw-w-screen tw-items-center tw-justify-center'>
          <Dialog.Panel className='tw-bg-[#1A202A] tw-rounded-lg tw-p-4 tw-max-w-sm lg:tw-max-w-xl tw-max-h-[466px] lg:tw-max-h-fit tw-overflow-y-auto tw-overflow-x-hidden tw-mt-12 lg:tw-mt-0'>
            <Dialog.Description as='div'>
              <div className='tw-p-3 md:tw-p-6 tw-flex tw-flex-col tw-gap-3 md:tw-gap-6 tw-items-center'>
                <p className='tw-text-center'>
                  Sua oferta esta expirando, não perca esta oportunidade!
                </p>
                <Button
                  className='focus:tw-outline-none'
                  onClick={handleTimeEnd}
                >
                  Continuar
                </Button>
              </div>
            </Dialog.Description>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

Countdown.propTypes = {
  timeInSeconds: PropTypes.number,
};
