/*eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state to trigger fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log('Caught an error:', error, errorInfo);
    // Log the error or send it to an external service here
  }

  handleReload = () => {
    // Optional: Provide a way to clear the cache and reload
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .getRegistrations()
        .then(registrations => {
          for (let registration of registrations) {
            registration.unregister();
          }
        })
        .catch(error => {
          console.log('Service worker unregistration failed:', error);
        });
    }

    if ('caches' in window) {
      caches
        .keys()
        .then(function (cacheNames) {
          cacheNames.forEach(function (cacheName) {
            caches.delete(cacheName);
          });
        })
        .then(() => {
          window.location.reload();
        });
    }
  };

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI here
      return (
        <div className="tw-flex tw-justify-center tw-items-center tw-h-screen tw-bg-secondary tw-flex-col tw-gap-4 tw-text-white">
          <h1 className="tw-text-4xl">O sistema passou por uma atualização.</h1>
          <button
            className="tw-bg-primary tw-px-3.5 tw-py-1.5 tw-rounded-lg hover:tw-bg-primary/80"
            onClick={this.handleReload}
          >
            Clique aqui para atualizar
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired // Ensure children are provided and are valid React nodes
};

export default ErrorBoundary;
